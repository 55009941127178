import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MatrixRain from './MatrixRain';
import './HomePage.css';

function HomePage() {
  const [quote, setQuote] = useState({});
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    async function fetchQuote() {
      const responseTech = await axios.get('https://api.quotable.io/random?tags=technology');
      const responseScience = await axios.get('https://api.quotable.io/random?tags=science');
      
      const techQuote = responseTech.data;
      const scienceQuote = responseScience.data;
      
      setQuote(techQuote || scienceQuote);
    }

    async function fetchWeather() {
      const response = await axios.get('https://ipapi.co/json/');
      const { latitude, longitude } = response.data;

      const weatherResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=ec0f7330295d86ed6afafb545838695e`
      );
      setWeather(weatherResponse.data);
    }

    fetchQuote();
    fetchWeather();
  }, []);

  return (
    <div className="home-container">
      <div className="home-box">
        <h1 className="home-title">Alexis Wu</h1>
        <p className="typewriter">
          Welcome to my website! I'm Xinyi (Alexis) Wu, an engineering student at Olin College of Engineering passionate about machine learning, software development, and robotics. With a strong background in Python, machine learning frameworks like PyTorch and TensorFlow, iOS app development, and web technologies like React and Flask, I thrive in multidisciplinary teams and contribute to innovative projects.
          <br />
          <br />

          I'm excited to apply my skills and contribute to a dynamic and innovative environment. Feel free to explore my website and reach out to me for any collaborations or inquiries. Thank you for visiting!
        </p>
      </div>
      {quote.content && (
        <div className="quote-container">
          <p className="quote-text">{quote.content}</p>
          <p className="quote-text">- {quote.author}</p>
        </div>
      )}
      {weather && weather.main && (
        <div className="weather-container">
          <h3 className="weather-text">{weather.name}</h3>
          <div className="weather-info">
            <img
              className="weather-icon"
              src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
              alt="Weather icon"
            />
            <div className="weather-text">
              <p>{Math.round((weather.main.temp - 273.15) * 9 / 5 + 32)}°F</p>
              <p>{weather.weather[0].description}</p>
            </div>
          </div>
        </div>
      )}
      <MatrixRain />
    </div>
  );
}

export default HomePage;
